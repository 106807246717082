import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/oee-monitoring',
    name: 'oee-monitoring',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OeeMonitoring.vue'),
  },
  {
    path: '/downtime-control',
    name: 'downtime-control',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DowntimeControl.vue'),
  },
  {
    path: '/action-plan-monitoring',
    name: 'action-plan-monitoring',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ActionPlanMonitoring.vue'),
  },
  {
    path: '/production-status',
    name: 'production-status',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ProductionStatus.vue'),
  },
  {
    path: '/quality-performance',
    name: 'quality-performance',
    component: () => import(/* webpackChunkName: "about" */ '@/views/QualityPerformance.vue'),
  },
  {
    path: '/melting-report',
    name: 'melting-report',
    component: () => import(/* webpackChunkName: "about" */ '@/views/MeltingReport.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
